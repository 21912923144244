import { DataService } from "../ares.model/data.service";
import { RemoteObj } from "../ares.model/remoteobj";
import { ServerData } from "../ares.model/serverdata";

export class DHIPUser extends RemoteObj{

    public RecNo : number = 0;
    public UserID : string = "";
    public CardNo : string = "";
    public CitizenIDNo : string = "";
    public UseTime : number = 0;
    public CardStatus : number = 0;
    public CardType : number = 0;
    public FirstEnter : boolean = false;
    public Password : string = "";
    public Doors : Array<number> = [0];
    public TimeSections : Array<number> = [255];
    public ValidDateStart : string = "";
    public ValidDateEnd : string = "";

    public constructor(){
        super();
    }

    public deserialize(data: ServerData, args: any[]): void {
        if(!data){
            throw "DHIPUser::deserialize: Provided data cannot be null!";
        }
        if(!args){
            DHIPUser.fill(this, data);
        }else{
            let obj : any = args[0];
            DHIPUser.fill(obj, data);
        }
    }

    public static fill(obj: any, data: ServerData){
        obj.RecNo = data.getData().RecNo;
        obj.UserID = data.getData().UserID;
        obj.CardNo = data.getData().CardNo;
        obj.CitizenIDNo = data.getData().CitizenIDNo;
        obj.UseTime = data.getData().UseTime;
        obj.CardStatus = data.getData().CardStatus;
        obj.CardType = data.getData().CardType;
        obj.FirstEnter = data.getData().FirstEnter;
        obj.Password = data.getData().Password;
        obj.ValidDateStart = data.getData().ValidDateStart;
        obj.ValidDateEnd = data.getData().ValidDateEnd;
    }

    public get(service: DataService, devName: string){
        return this.fetchData(service, "/aresuser/byid/"+devName+"/"+ this.UserID);
    }

    public put(service : DataService, devName : string){
        return this.putData(service, "/aresuser/update/"+devName)
    }

    public delete(service: DataService, devName : string){
        return this.deleteData(service, "/aresuser/remove/"+devName+"/"+this.UserID);
    }

    public create(service: DataService, devName : string){
        return this.postData(service, "/aresuser/add/"+devName);
    }
}