import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServerData, ServerDataIFace } from './serverdata';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private baseUrl : string = "/aresgate";

  constructor(private http: HttpClient) { }

  public async doPost(obj: any, relativeUrl: string, args: Array<any> = null,
      callback: any = null, cBackArgs: Array<any> = null) : Promise<ServerData>{
    const uriString = this.getUri(relativeUrl, args);
    return this.http.post<ServerDataIFace>(uriString,  obj).toPromise().then(
      (data) => {
        let sData = new ServerData();
        try{
          sData.fromIFace(data);
        }catch(err){
          console.log(err);
          sData.setError(err);
          sData.setStatus(0);
        }
        if(callback){
          callback(sData, cBackArgs);
        }
        return sData;
      }
    );
  }

  public doPut(obj: any, relativeUrl: string, args: Array<any> = null,
      callback: any = null, cBackArgs: Array<any> = null) : Promise<ServerData>{
    const uriString = this.getUri(relativeUrl, args);
    return this.http.put<ServerDataIFace>(uriString,  obj).toPromise().then(
      (data) => {
        let sData = new ServerData();
        try{
          sData.fromIFace(data);
        }catch(err){
          console.log(err);
          sData.setError(err);
          sData.setStatus(0);
        }
        if(callback){
          callback(sData, cBackArgs);
        }
        return sData;
      }
    );
  }

  public doGet(relativeUrl: string, args: Array<any> = null, 
      callback: any = null, cBackArgs: Array<any> = null) : Promise<ServerData> {
    const uriString = this.getUri(relativeUrl, args);
    return this.http.get<ServerDataIFace>(uriString).toPromise().then(
      (data) => {
        let sData = new ServerData();
        //console.log(data);
        try{
          sData.fromIFace(data);
        }catch(err){
          console.log(err);
          sData.setError(err);
          sData.setStatus(0);
        }
        if(callback){
          if(sData.getStatus()){
            callback(sData, cBackArgs);
          }else{
            console.log(sData.getError());
          }
        }
        return sData;
      }
    );
  }

  public async doDelete(relativeUrl: string, args: Array<any> = null) : Promise<ServerData> {
    const uriString = this.getUri(relativeUrl, args);
    return await this.http.delete<ServerDataIFace>(uriString).toPromise().then(
      (data) => {
        let sData = new ServerData();
        try{
          sData.fromIFace(data);
        }catch(err){
          console.log(err);
          sData.setError(err);
          sData.setStatus(0);
        }
        return sData;
      }
    );
  }

  public getUri(relativeUrl: string, args: Array<any>) : string{
    let paramsString = "";
    let uriString = this.baseUrl + relativeUrl;
    if(args){
      args.forEach(element => {
        paramsString += "/"+element;
      });
    }
    uriString += paramsString;
    /*if(this.user.user && this.user.user.token != ""){
      uriString += "/"+this.user.user.token;
    }*/
    return uriString;
  }

}
