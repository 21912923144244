import { DHIPUser } from "../ares.dhip/dhipuser";
import { DataService } from "../ares.model/data.service";
import { RemoteObj } from "../ares.model/remoteobj";
import { ServerData } from "../ares.model/serverdata";
import { ArgonMacro } from "./argonmacro";

export class ArgonHost extends RemoteObj{

    public hostid : string = "";
    public host : string = "";
    public name : string = "";
    public macros : Array<ArgonMacro> = [];
    public accessCode : DHIPUser = null;

    public constructor(){
        super();
        this.accessCode = new DHIPUser();
    }

    public deserialize(data: ServerData, args: any[]): void {
        if(!data){
            throw "ArgonHost::deserialize: Provided data cannot be null!";
        }
        if(!args){
            ArgonHost.fill(this, data);
        }else{
            let obj : any = args[0];
            ArgonHost.fill(obj, data);
        }
    }

    public static fill(obj: any, data: ServerData){
        let macros : Array<any> = data.getData().macros;
        let macroData : ServerData = new ServerData();
        obj.hostid = data.getData().hostid;
        obj.host = data.getData().host;
        obj.name = data.getData().name;
        obj.macros = [];
        if(macros){
            macros.forEach((m) => {
                let newMacro = new ArgonMacro();
                macroData.setData(m);
                newMacro.deserialize(macroData, null);
                obj.macros.push(newMacro);
            });
        }
    }

    public getMacroByName(name : string) : ArgonMacro{
        let m : ArgonMacro = null;
        this.macros.forEach((macro) =>{
            if(macro.macro == name){
                m = macro;
                return;
            }
        });
        return m;
    }

    public getAccessCode(dataHandler : DataService){
        /*let m : ArgonMacro = this.getMacroByName("{$MRC.NAME}");
        if(!m){
            throw "ArgonHost::getAccessCode: No MRC.NAME Macro!";
        }*/
        return this.accessCode.fetchData(dataHandler, "/aresapp/random/"+this.name);
    }
}