import { RemoteObj } from "../ares.model/remoteobj";
import { ServerData } from "../ares.model/serverdata";

export class ArgonUser extends RemoteObj{

    public userid : string = "";
    public username : string = "";
    public name : string = "";
    public surname : string = "";
    public sessionid : string = "";

    public constructor(){
        super();
    }

    public deserialize(data: ServerData, args: any[]): void {
        if(!data){
            throw "ArgonUser::deserialize: Provided data cannot be null!";
        }
        if(!args){
            ArgonUser.fill(this, data);
        }else{
            let obj : any = args[0];
            ArgonUser.fill(obj, data);
        }
    }

    public static fill(obj: any, data: ServerData){
        obj.userid = data.getData().userid;
        obj.username = data.getData().username;
        obj.name = data.getData().name;
        obj.surname = data.getData().surname;
        obj.sessionid = data.getData().sessionid;
    }

}