import { AngularDelegate } from '@ionic/angular';
import { DataService } from './data.service';
import { ServerData } from './serverdata';

export abstract class RemoteObj {
    //public data: any = null;

    public remoteUrl = "/";

    public constructor() {

    }

    /*
    public fromIFace(iface: any){
        if(!iface){
            throw "RemoteObj::fromIFace: provided iface cannot be null!";
        }
        this.data = iface;
    }

    public getData(){
        return this.data;
    }

    public setData(obj: any){
        this.data = obj;
    }
    */

    public clone(obj : RemoteObj) : void {
        let objCopyStr : string = JSON.stringify(obj);
        let data : ServerData = new ServerData();
        data.setData(JSON.parse(objCopyStr));
        this.deserialize(data, null);
    }
    
    public abstract deserialize(data: ServerData, args: Array<any>): void;

    public async fetchData(service: DataService, url: string, 
            urlArgs : Array<any> = null, isAsync: boolean = false) : Promise<ServerData>{
        let data: ServerData = null;
        if (!service) {
            throw "RemoteObj::fetchData: provided service cannot be null!";
        }
        if (!isAsync) {
            data = await service.doGet(url, urlArgs);
            //data.handleError();//cannot be called here: exception will be thrown in Promise
            if(data.getStatus()){
                this.deserialize(data, null);
            }
        } else {
            return service.doGet(url, urlArgs, this.deserialize, [this]);
        }
        return data;
    }

    public async putData(service: DataService, url: string, 
            urlArgs : Array<any> = null, isAsync: boolean = false) : Promise<ServerData>{
        let data: ServerData = null;
        if (!service) {
            throw "RemoteObj::putData: provided service cannot be null!";
        }
        if (!isAsync) {
            data = await service.doPut(this, url, urlArgs);
            //data.handleError();//cannot be called here: exception will be thrown in Promise
        } else {
            return service.doPut(this, url, urlArgs);
        }
        return data;
    }

    public async postData(service: DataService, url: string, 
        urlArgs : Array<any> = null, isAsync: boolean = false)
        : Promise<ServerData> {
            let data: ServerData = null;
            if (!service) {
                throw "RemoteObj::postData: provided service cannot be null!";
            }
            if (!isAsync) {
                data = await service.doPost(this, url, urlArgs);
                //data.handleError();//cannot be called here: exception will be thrown in Promise
            } else {
                return service.doPost(this, url, urlArgs);
            }
            return data;
    }

    public async deleteData(service: DataService, url: string, 
        urlArgs : Array<any> = null, isAsync: boolean = false)
        : Promise<ServerData> {
            let data: ServerData = null;
            if (!service) {
                throw "RemoteObj::deleteData: provided service cannot be null!";
            }
            if (!isAsync) {
                data = await service.doDelete(url, urlArgs);
                //data.handleError();//cannot be called here: exception will be thrown in Promise
            } else {
                return service.doDelete(url, urlArgs);
            }
            return data;
    }

}