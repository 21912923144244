
export interface ServerDataIFace {
    status: number;
    data: any;
    error: string;
    
};

export class ServerData {
    public data : ServerDataIFace = null;
    constructor(){
        this.data = {
            status: 0,
            data: {},
            error: ""
        }
    }

    public getData(){
        return this.data.data;
    }

    public setData(data: any) : void {
        this.data.data = data;
    }

    public fromIFace(iface: ServerDataIFace){
        this.data = iface;
    }

    public handleError() {
        if(!this.data){
            throw "ServerData::handleError: No data from the server"
        }
        if(!this.data.status){
            throw this.data.error;
        }
    }

    public setError(err: string){
        if(!this.data){
            this.data = {
                status: 0,
                error: err,
                data: null
            }
        }else{
            this.data.error = err;
            this.data.status = 0;
        }
        
    }

    public getError() : string{
        if(!this.data){
            throw "ServerData::getError: this data is NUll"
        }
        return this.data.error;
    }

    public getStatus(){
        return this.data.status;
    }

    public setStatus(val: number){
        this.data.status = val;
    }

}