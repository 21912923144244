import { DataService } from "../ares.model/data.service";
import { RemoteObj } from "../ares.model/remoteobj";
import { ServerData } from "../ares.model/serverdata";
import { ArgonHost } from "./argonhost";
import { ArgonUser } from "./argonuser";

export class ArgonManager extends RemoteObj{

    public user : ArgonUser = new ArgonUser();
    public list : Array<ArgonHost> = [];

    public constructor(){
        super();
    }

    public deserialize(data: ServerData, args: any[]): void {
        if(!data){
            throw "ArgonManager::deserialize: Provided data cannot be null!";
        }
        if(!args){
            ArgonManager.fill(this, data);
        }else{
            let obj : any = args[0];
            ArgonManager.fill(obj, data);
        }
    }

    public static fill(obj: any, data: ServerData){
        let listData : ServerData = new ServerData();
        let hList : Array<any> = data.getData().list;
        obj.list = [];
        if(hList){
            hList.forEach((host) => {
                let aHost = new ArgonHost();
                listData.setData(host);
                aHost.deserialize(listData, null);
                obj.list.push(aHost);
            });
        }
    }

    public login(dataHandler : DataService){
        return this.user.putData(dataHandler, "/aresapp/login");
    }

    public logout(dataHandler : DataService){
        return this.user.putData(dataHandler, "/aresapp/logout");
    }

    public getDevicesList(dataHandler : DataService){
        return this.fetchData(dataHandler, "/aresapp/pvslist/"+this.user.userid+"/"+this.user.sessionid);
    }

}