import { Injectable } from '@angular/core';
import { ArgonManager } from '../ares.argon/argonmanager';

@Injectable({
  providedIn: 'root'
})
export class AresuserService {

  public argon : ArgonManager = new ArgonManager();

  constructor() { 

  }


}
