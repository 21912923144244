import { RemoteObj } from "../ares.model/remoteobj";
import { ServerData } from "../ares.model/serverdata";

export class ArgonMacro extends RemoteObj{

    public macro : string = "";
    public value : string = "";

    public constructor(){
        super();
    }

    public deserialize(data: ServerData, args: any[]): void {
        if(!data){
            throw "ArgonMacro::deserialize: Provided data cannot be null!";
        }
        if(!args){
            ArgonMacro.fill(this, data);
        }else{
            let obj : any = args[0];
            ArgonMacro.fill(obj, data);
        }
    }

    public static fill(obj: any, data: ServerData){
        obj.macro = data.getData().macro;
        obj.value = data.getData().value;
    }

}