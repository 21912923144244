import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ArgonManager } from './ares.argon/argonmanager';
import { AresuserService } from './ares.model/aresuser.service';
import { DataService } from './ares.model/data.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    /*{ title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
    { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
    { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
    { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
    { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    { title: 'Spam', url: '/folder/Spam', icon: 'warning' },*/
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  public argon : ArgonManager = null;

  constructor(
    private argonService : AresuserService,
    private router : Router,
    private dataHandler : DataService
  ) {
    this.argon = this.argonService.argon;
  }

  public logout(){
    this.argon.logout(this.dataHandler);
    this.router.navigate(["login"]);
  }
}
